<template>
  <Breadcrumb />
  <div class="row justify-content-end">
    <div class="col-md-4">
      <div
        class="alert alert-primary p-1 br-dashboard-period-alert alert-dismissible fade show"
        role="alert"
      >
        <text
          v-if="deletionPeriodDaysForSuccessfully"
          v-html="
            $t(
              'BusinessProcessDashboard.DeletionPeriodDaysForSuccessfully',
              {},
              { locale: this.$store.state.activeLang }
            ).replace('NUMBER', deletionPeriodDaysForSuccessfully)
          "
        ></text>
        <div
          v-else
          class="spinner-grow spinner-grow-sm text-warning"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-flex-cards">
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-dark">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.businessProcessCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.BusinessProcessCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-primary">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.businessRuleCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.BusinessRuleCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-success">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.businessRuleActionCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.BusinessRuleActionCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-info">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.createRecordCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.CreateRecordCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-warning">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.updateRecordCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.UpdateRecordCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="row row-flex-cards">
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-dark">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.sendNotificationCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.SendNotificationCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-primary">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.sendHTTPRequestCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.SendHTTPRequestCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-success">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.sendReportCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.SendReportCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-info">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.sendXtraReportCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.SendXtraReportCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
    <div class="col-md-2-5">
      <div class="counter-box counter-box-padding bg-warning">
        <span class="counter text-white">
          <template v-if="businessRuleStatistics">
            {{ businessRuleStatistics.sendDashboardCount }}
          </template>
          <template v-else>
            <div class="spinner-grow text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
        </span>
        <p class="text-white fs-16">
          {{
            $t(
              "BusinessProcessDashboard.SendDashboardCount",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4">
      <p class="border-primary br-dashboard-title">
        {{
          $t(
            "BusinessProcessDashboard.BusinessRuleStatistics",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </p>
      <SetLoader v-if="chartDataSource.length == 0" />
      <DxChart
        id="businessRuleDashboardBarChart"
        :data-source="chartDataSource"
        :customize-point="customizePoint"
        :customize-label="customizeLabel"
        palette="Soft"
        v-else
      >
        <DxCommonSeriesSettings
          :ignore-empty-points="true"
          argument-field="statusType"
          value-field="statusCount"
          :showInLegend="false"
          type="bar"
        />
        <DxSeriesTemplate name-field="statusType" />
      </DxChart>
    </div>
    <div class="col-md-8">
      <p
        class="border-primary br-dashboard-title"
        v-html="
          $t(
            'BusinessProcessDashboard.LastFailedJobs',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
      ></p>
      <div class="overflow-grid-block">
        <Grid
          :settings="gridSettings"
          :columns="gridColumns"
          :pageNumberStartFrom="0"
        />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import {
  DxChart,
  DxSeriesTemplate,
  DxCommonSeriesSettings,
} from "devextreme-vue/chart";
export default {
  name: "BusinessProcessDashboard",
  components: {
    DxChart,
    DxSeriesTemplate,
    DxCommonSeriesSettings,
  },
  data() {
    return {
      chartDataSource: [],
      businessRuleStatistics: null,
      deletionPeriodDaysForSuccessfully: null,
      gridSettings: {
        action: "BusinessProcessDashboardLastFailedJobs",
        requestUrl: "Brs-MonitoringGetJobs?statusTypeId=1",

        isGatewayRequest: true,

        allowPaging: true,
        autoOrderProcess: true,
        isBrsRequest: true,
        isPublicIdColumnHide: true,
        isCommandColumnHide: true,

        buttons: [],
      },
      gridColumns: [
        {
          text: this.$t(
            "ImportBulkRecordData.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: true,
          width: 0,
          template: "",
          format: "",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.StateReason",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "stateReason",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.StateData",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "stateData",
          type: "string",
          visible: true,
          width: 150,
          template: "jsonViewerTemplate",
          format: "",
        },
      ],
    };
  },
  mounted() {
    this.getBusinessRuleStatistics();
  },
  methods: {
    customizeLabel(info) {
      switch (info.argument) {
        case this.$t(
          "ImportBulkRecordData.Deleted",
          {},
          { locale: this.$store.state.activeLang }
        ):
          return {
            visible: true,
            backgroundColor: "#dc3545",
          };
        case this.$t(
          "ImportBulkRecordData.Failed",
          {},
          { locale: this.$store.state.activeLang }
        ):
          return {
            visible: true,
            backgroundColor: "#fd7e14",
          };
        case this.$t(
          "ImportBulkRecordData.Succeeded",
          {},
          { locale: this.$store.state.activeLang }
        ):
          return {
            visible: true,
            backgroundColor: "#198754",
          };
        default:
          break;
      }
    },
    customizePoint(info) {
      switch (info.argument) {
        case this.$t(
          "ImportBulkRecordData.Deleted",
          {},
          { locale: this.$store.state.activeLang }
        ):
          return { color: "#dc3545", hoverStyle: { color: "#dc3545" } };
        case this.$t(
          "ImportBulkRecordData.Failed",
          {},
          { locale: this.$store.state.activeLang }
        ):
          return { color: "#fd7e14", hoverStyle: { color: "#fd7e14" } };
        case this.$t(
          "ImportBulkRecordData.Succeeded",
          {},
          { locale: this.$store.state.activeLang }
        ):
          return { color: "#198754", hoverStyle: { color: "#198754" } };
        default:
          break;
      }
    },
    getJobsCountByState() {
      var self = this;
      this.$prodGatewayAxios
        .get(String.format("/Brs-MonitoringGetJobsCountByState"))
        .then((response) => {
          var data = response.data;
          this.deletionPeriodDaysForSuccessfully =
            data.deletionPeriodDaysForSuccessfully;
          if (data.list) {
            Object.keys(data.list).forEach(function (key) {
              if (!String.isNullOrWhiteSpace(key)) {
                self.chartDataSource.push({
                  statusType: self.$t(
                    "ImportBulkRecordData." + key,
                    {},
                    { locale: self.$store.state.activeLang }
                  ),
                  statusCount: data.list[key],
                  backgroundColor: "#000",
                });
              }
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        })
        .finally(() => {
          //
        });
    },
    animationReady() {
      $(".counter").each(function () {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text(),
            },
            {
              duration: 2000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
            }
          );
      });
    },
    getBusinessRuleStatistics() {
      this.businessRuleStatistics = null;
      this.$prodGatewayAxios
        .get("/Brs-BusinessRuleActionProcessGetBusinessRuleStatistics")
        .then((response) => {
          this.businessRuleStatistics = response.data;
        })
        .catch(function (error) {
          //alert(error);
        })
        .finally(() => {
          this.getJobsCountByState();
          this.animationReady();
        });
    },
  },
};
</script>
